//-----------------------------------------------------------------------------
// Unit Name: u_voucher
// Author:    Rademacker
// Date:      2025-03-18
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit u_voucher;

interface

uses
    System.SysUtils,
    System.Classes,
    JS,
    Web,
    WEBLib.Graphics,
    WEBLib.Controls,
    WEBLib.Forms,
    WEBLib.Dialogs,
    uc_page,
    WEBLib.Menus,
    WEBLib.StdCtrls;

type
  TfrmVoucher = class(TfrmPage)
    edmenge: TLabel;
    edzeit: TLabel;
    eddatum: TLabel;
    edausgabe: TLabel;
    edid: TLabel;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    procedure SetVoucherInfo(const cId: string);
  protected procedure LoadDFMValues; override; end;

implementation

uses
    i_user;

{$R *.dfm}

// -----------------------------------------------------------------------------
// Procedure: TfrmVoucher.SetVoucherInfo
// Author:    Rademacker
// Date:      2025-03-18
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmVoucher.SetVoucherInfo(const cId: string);
var oVoucher: TxVoucher;
begin
//    edid.Text      := '';
//    eddatum.Text   := '';
//    edzeit.Text    := '';
//    edausgabe.Text := '';
//    edmenge.Text   := '';

    oVoucher := oUser.GetVoucher(cId);

    edid.Caption      := oVoucher.cId;
    eddatum.Caption   := oVoucher.cDatum;
    edzeit.Caption    := oVoucher.cZeit;
    edausgabe.Caption := oVoucher.cAusgabe;
    edmenge.Caption   := IntToStr(oVoucher.nMenge);
end;

procedure TfrmVoucher.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edmenge := TLabel.Create('ed_menge');
  edzeit := TLabel.Create('ed_zeit');
  eddatum := TLabel.Create('ed_datum');
  edausgabe := TLabel.Create('ed_ausgabe');
  edid := TLabel.Create('ed_id');

  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  edmenge.BeforeLoadDFMValues;
  edzeit.BeforeLoadDFMValues;
  eddatum.BeforeLoadDFMValues;
  edausgabe.BeforeLoadDFMValues;
  edid.BeforeLoadDFMValues;
  try
    WebPanel1.Height := 38;
    WebButton1.Height := 30;
    SetEvent(WebButton1, Self, 'OnClick', 'btnBackClick');
    edmenge.SetParentComponent(Self);
    edmenge.Name := 'edmenge';
    edmenge.Left := 0;
    edmenge.Top := 0;
    edmenge.Width := 101;
    edmenge.Height := 30;
    edzeit.SetParentComponent(Self);
    edzeit.Name := 'edzeit';
    edzeit.Left := 101;
    edzeit.Top := 0;
    edzeit.Width := 101;
    edzeit.Height := 30;
    eddatum.SetParentComponent(Self);
    eddatum.Name := 'eddatum';
    eddatum.Left := 202;
    eddatum.Top := 0;
    eddatum.Width := 101;
    eddatum.Height := 30;
    edausgabe.SetParentComponent(Self);
    edausgabe.Name := 'edausgabe';
    edausgabe.Left := 303;
    edausgabe.Top := 0;
    edausgabe.Width := 101;
    edausgabe.Height := 30;
    edid.SetParentComponent(Self);
    edid.Name := 'edid';
    edid.Left := 404;
    edid.Top := 0;
    edid.Width := 101;
    edid.Height := 30;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    edmenge.AfterLoadDFMValues;
    edzeit.AfterLoadDFMValues;
    eddatum.AfterLoadDFMValues;
    edausgabe.AfterLoadDFMValues;
    edid.AfterLoadDFMValues;
  end;
end;

end.


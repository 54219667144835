program rzs_app;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  a_main in 'a_main.pas'{*.html},
  u_login in 'u_login.pas'{*.html},
  i_user in 'i_user.pas',
  lib_Utils in '..\app_lib\lib_Utils.pas',
  uc_page in 'uc_page.pas'{*.html},
  u_scanner in 'u_scanner.pas'{*.html},
  u_error in 'u_error.pas'{*.html},
  u_voucher in 'u_voucher.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TfrmMain, frmMain);
  Application.Run;
end.

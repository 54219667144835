//-----------------------------------------------------------------------------
// Unit Name: i_user
// Author:    Rademacker
// Date:      2025-02-04
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit i_user;

interface

uses
    JS,
    lib_REST;

type
    TxVoucher = record
        cId      : string;
        cDatum   : string;
        cZeit    : string;
        cAusgabe : string;
        cTyp     : string;
        nMenge   : integer;
        cFreigabe: string;
    end;

    TxUser = class(TObject)
    private
        cUsername : string;
        cKdNr     : string;
        cName     : string;
        cAdresse  : string;
        nInterval : integer;
        nMenge    : integer;
        cGewerbe  : string;
        cStatus   : string;
        nGsOffen  : integer;
        nGsGesamt : integer;
        cGsNext   : string;
        cGsNew    : string;
        aVoucher  : TArray<TxVoucher>;
        lAuth     : Boolean;
        oRest     : TxRestRequest;
        procedure Reset();
        //procedure RestResponse(Sender: TObject);
        //procedure LoginResponse(Sender: TObject);
        function DecodeJWTPayload(cToken: string): string;
        function JwtLogin(const cJwt: string; const lSave: Boolean): Boolean;
        procedure SetUserInfo();
        function AddVoucher(oVoucher: TJSObject): integer;
    public
        constructor Create();
        destructor Destroy(); override;
        [async] function DoAuth(const cUser, cPass: string; const lSave: Boolean): TJSPromise; async;
        [async] function GetUserInfo(): TJSPromise; async;
        [async] function GetNewVoucher(const cCode: string): TJSPromise; async;
        procedure Logout();
        function CheckLogin(): Boolean;
        function GetVoucher(const cId: string): TxVoucher;
    public
        property Authenticated: Boolean read lAuth;
        property Username: string  read cUsername;
        property KdNr    : string  read cKdNr;
        property Name    : string  read cName;
        property Adresse : string  read cAdresse;
        property Interval: integer read nInterval;
        property Menge   : integer read nMenge;
        property Gewerbe : string  read cGewerbe;
        property Status  : string  read cStatus;
        property GsOffen : integer read nGsOffen;
        property GsGesamt: integer read nGsGesamt;
        property GsNext  : string  read cGsNext;
        property GsNew   : string  read cGsNew;
        property Voucher : TArray<TxVoucher> read aVoucher;
    end;

var oUser: TxUser;

implementation

uses
    System.Classes,
    System.SysUtils,
    Rtl.HTMLUtils,
    WEBLib.Storage,
    Web;

// -----------------------------------------------------------------------------
// Procedure: TxUser.Create
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

constructor TxUser.Create();
begin
    inherited Create();
    Reset();
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.Destroy
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

destructor TxUser.Destroy();
begin
    inherited Destroy();
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.DoAuth
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

function TxUser.DoAuth(const cUser, cPass: string; const lSave: Boolean): TJSPromise;
var oParams: TStringList;
    cJwt   : string;
begin
    // TODO simulation von Serverresponse
    await(boolean, AsyncSleep(10));

    oParams := TStringList.Create();

    // TODO verschlüsselung
    oParams.Add('user=' + cUser);
    oParams.Add('pass=' + cPass);

    if (await(boolean, oRest.Get('oauth', oParams))) then begin

        cJwt := JS.toString(oRest.Response['token']);
        if (JwtLogin(cJwt, lSave)) then begin
            result := TJSPromise.new(procedure(ASuccess, AFailed: TJSPromiseResolver)
            begin
                ASuccess(true);
            end);
        end;

    end;

    FreeAndNil(oParams);
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.GetUserInfo
// Author:    Rademacker
// Date:      2025-03-13
// Comment:
// -----------------------------------------------------------------------------

function TxUser.GetUserInfo(): TJSPromise;
begin
    if (await(boolean, oRest.Get('rzs/user/v1'))) then begin

        SetUserInfo();

        result := TJSPromise.new(procedure(ASuccess, AFailed: TJSPromiseResolver)
        begin
            ASuccess(true);
        end);
    end;
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.GetNewVoucher
// Author:    Rademacker
// Date:      2025-03-17
// Comment:
// -----------------------------------------------------------------------------

function TxUser.GetNewVoucher(const cCode: string): TJSPromise;
var oParams: TStringList;
    //nPos   : integer;
begin
    Self.cGsNew := '';

    oParams := TStringList.Create();

    oParams.Add('ausgabe=' + cCode);

    if (await(boolean, oRest.Post('rzs/user/v1', oParams))) then begin

        if (oRest.Response.hasOwnProperty('Id')) then begin

            Self.cGsNew := JS.toString(oRest.Response['Id']);

            result := TJSPromise.new(procedure(ASuccess, AFailed: TJSPromiseResolver)
            begin
                ASuccess(true);
            end);
        end;
    end;

    FreeAndNil(oParams);
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.Logout
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TxUser.Logout();
begin
    TLocalStorage.SetValue('JWT', '');
    Reset();
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.CheckLogin
// Author:    Rademacker
// Date:      2025-03-13
// Comment:
// -----------------------------------------------------------------------------

function TxUser.CheckLogin(): Boolean;
var cJwt: string;
begin
    if (not lAuth) then begin
        cJwt := TLocalStorage.GetValue('JWT');
        JwtLogin(cJwt, false);
    end;

    result := lAuth;
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.GetVoucher
// Author:    Rademacker
// Date:      2025-03-18
// Comment:
// -----------------------------------------------------------------------------

function TxUser.GetVoucher(const cId: string): TxVoucher;
var oVouch: TxVoucher;
begin
    if (not cId.IsEmpty) then begin
        for oVouch in aVoucher do begin
            if (oVouch.cId = cId) then begin
                result := oVouch;
                break;
            end;
        end;
    end;
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.Reset
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TxUser.Reset();
begin
    Self.cUsername := '';
    Self.cKdNr     := '';
    Self.cName     := '';
    Self.cAdresse  := '';
    Self.nInterval := 0;
    Self.nMenge    := 0;
    Self.cGewerbe  := '';
    Self.cStatus   := '';
    Self.nGsOffen  := 0;
    Self.nGsGesamt := 0;
    Self.cGsNext   := '';
    Self.cGsNew    := '';
    Self.lAuth     := false;

    SetLength(Self.aVoucher, 0);

    if (Assigned(oRest)) then begin
        oRest.Free();
    end;

    oRest := TxRestRequest.Create();

    //oRest.Host       := '127.0.0.1';
    //oRest.Host       := '192.168.254.150';
    //oRest.Port       := 8080;
    oRest.Host       := 'rest.bergau.de';
    oRest.Port       := 1338;
    oRest.ApiKey     := '82nm7p-mdrt-6aeu-wotd-db9pdx';
    //oRest.Ssl        := false; // TODO für testscenario
    //oRest.OnResponse := nil;

    //oRest.Get('app/demo/v1');
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.RestResponse
// Author:    Rademacker
// Date:      2025-02-24
// Comment:
// -----------------------------------------------------------------------------

//procedure TxUser.RestResponse(Sender: TObject);
//begin
//
//end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.LoginResponse
// Author:    Rademacker
// Date:      2025-02-24
// Comment:
// -----------------------------------------------------------------------------

//procedure TxUser.LoginResponse(Sender: TObject);
//begin
//    //oRest.OnResponse := nil;
//end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.DecodeJWTPayload
// Author:    Rademacker
// Date:      2025-03-13
// Comment:
// -----------------------------------------------------------------------------

function TxUser.DecodeJWTPayload(cToken: string): string;
var s: string;
begin
    s := '';
    asm
        var base64Url = cToken.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        s = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    end;
    result := s;
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.JwtLogin
// Author:    Rademacker
// Date:      2025-03-13
// Comment:
// -----------------------------------------------------------------------------

function TxUser.JwtLogin(const cJwt: string; const lSave: Boolean): Boolean;
var oJwt : TJSObject;
    cUser: string;
begin
    result := false;

    if (not cJwt.IsEmpty) then begin

        oJwt  := TJSObject(TJSJSON.parse(DecodeJWTPayload(cJwt)));
        cUser := JS.toString(oJwt['aud']);
        if (not cUser.IsEmpty) then begin
            oRest.Jwt := cJwt;

            if (lSave) then begin
                TLocalStorage.SetValue('JWT', cJwt);
            end;

            // Benutzerdaten speichern
            lAuth      := true;
            cUsername  := cUser;

            result := true;
        end;
    end;
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.SetUserInfo
// Author:    Rademacker
// Date:      2025-03-18
// Comment:
// -----------------------------------------------------------------------------

procedure TxUser.SetUserInfo();
var oArr: TJSArray;
    i   : integer;
begin
    if (Assigned(oRest.Response)) then begin
        Self.cKdNr     := JS.toString(oRest.Response['KdNr']);
        Self.cName     := JS.toString(oRest.Response['Name']);
        Self.cAdresse  := JS.toString(oRest.Response['Adresse']);
        Self.nInterval := JS.toInteger(oRest.Response['Interval']);
        Self.nMenge    := JS.toInteger(oRest.Response['Menge']);
        Self.cGewerbe  := JS.toString(oRest.Response['Gewerbe']);
        Self.cStatus   := JS.toString(oRest.Response['Status']);

        Self.nGsOffen  := JS.toInteger(oRest.Response['Gutschein_offen']);
        Self.nGsGesamt := JS.toInteger(oRest.Response['Gutschein_gesamt']);
        Self.cGsNext   := JS.toString(oRest.Response['Gutschein_next']);
        //Self.cGsNew    := JS.toString(oRest.Response['Gutschein_neu']);

        oArr := TJSArray(oRest.Response['Gutscheine']);

        for i := 0 to oArr.Length - 1 do begin
            AddVoucher(TJSObject(oArr[i]));
        end;
    end;
end;

// -----------------------------------------------------------------------------
// Procedure: TxUser.AddVoucher
// Author:    Rademacker
// Date:      2025-03-17
// Comment:
// -----------------------------------------------------------------------------

function TxUser.AddVoucher(oVoucher: TJSObject): integer;
begin
    result := Length(Self.aVoucher);
    SetLength(Self.aVoucher, result + 1);

    Self.aVoucher[result].cId       := JS.toString(oVoucher['Id']);
    Self.aVoucher[result].cDatum    := JS.toString(oVoucher['Datum']);
    Self.aVoucher[result].cZeit     := JS.toString(oVoucher['Zeit']);
    Self.aVoucher[result].cAusgabe  := JS.toString(oVoucher['Ausgabe']);
    Self.aVoucher[result].cTyp      := JS.toString(oVoucher['Typ']);
    Self.aVoucher[result].nMenge    := JS.toInteger(oVoucher['Menge']);
    Self.aVoucher[result].cFreigabe := JS.toString(oVoucher['Freigabe']);
end;

end.

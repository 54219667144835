unit libmedia;

interface

{$mode objfpc}
{$modeswitch externalclass}
{$DEFINE NOPP}

uses
  Web, JS, Classes, Types;

type
  TWebMediaStreamTrack = class external name 'MediaStreamTrack'
    procedure stop;
    function applyConstraints: TJSPromise; overload;
    function applyConstraints(aConstraints: TJSObject): TJSPromise; overload;
    function getConstraints: TJSObject;
    function getCapabilities: TJSObject;
    function getSettings: TJSObject;
  end;

  TJSMediaStreamTrackEx = TWebMediaStreamTrack;

  TWebMediaStream = class external name 'MediaStream' (TJSObject)
    procedure addTrack(aTrack: TWebMediaStreamTrack);
    function getAudioTracks: TJSArray;
    function getTracks: TJSArray;
    function getVideoTracks: TJSArray;
  end;

  TJSMediaStreamEx = TWebMediaStream;

  TWebAudioNode = class external name 'AudioNode'
    function connect(aDestination: TWebAudioNode): TWebAudioNode;
    procedure disconnect; overload;
    procedure disconnect(aDestination: TWebAudioNode); overload;
  end;

  TWebAnalyserNode = class external name 'AnalyserNode' (TWebAudioNode)
  private
    FFFTSize: NativeInt; external name 'fftSize';
    FFrequencyBinCount: NativeInt; external name 'frequencyBinCount';
    FMaxDecibels: Double; external name 'maxDecibels';
    FMinDecibels: Double; external name 'minDecibels';
    FSmoothingTimeConstant: Double; external name 'smoothingTimeConstant';
  public
    procedure getFloatFrequencyData(aData: TJSFloat32Array);
    procedure getByteFrequencyData(aData: TJSUint8Array);
    procedure getFloatTimeDomainData(aData: TJSFloat32Array);
    procedure getByteTimeDomainData(aData: TJSUint8Array);
    property FFTSize: NativeInt read FFFTSize write FFFTSize;
    property FrequencyBinCount: NativeInt read FFrequencyBinCount;
    property MaxDecibels: Double read FMaxDecibels write FMaxDecibels;
    property MinDecibels: Double read FMinDecibels write FMinDecibels;
    property SmoothingTimeConstant: Double read FSmoothingTimeConstant write FSmoothingTimeConstant;
  end;

  TJSAnalyserNodeEx = TWebAnalyserNode;

  TWebMediaStreamAudioSourceNode = class external name 'MediaStreamAudioSourceNode' (TWebAudioNode)
  end;

  TJSMediaStreamAudioSourceNodeEx = TWebMediaStreamAudioSourceNode;

  TWebBaseAudioContext = class external name 'BaseAudioContext'
    function createAnalyser: TWebAnalyserNode;
  end;

  TJSBaseAudioContextEx = TWebBaseAudioContext;

  TWebAudioContext = class external name 'AudioContext' (TWebBaseAudioContext)
    function createMediaStreamSource(aStream: TWebMediaStream): TWebMediaStreamAudioSourceNode;
    function resume: TJSPromise;
  end;

  TJSAudioContextEx = TWebAudioContext;

  TWebMediaRecorderStopEvent = reference to procedure;

  TWebMediaRecorder = class external name 'MediaRecorder' (TJSObject)
    ondataavailable: TJSEventHandler;
    onstop: TWebMediaRecorderStopEvent;
    constructor new (aStream : TWebMediaStream); overload;
    constructor new (aStream : TWebMediaStream; aOptions : TJSObject); overload;
    procedure start;
    procedure stop;
  end;

  TJSMediaRecorderEx = TWebMediaRecorder;

  TWebHTMLMediaElement = class external name 'HTMLMediaElement' (TJSHTMLElement)
    paused: Boolean;
    srcObject: TJSObject;
    procedure pause;
    procedure play;
  end;

  TJSHTMLMediaElementEx = TWebHTMLMediaElement;

  TWebHTMLVideoElement = class external name 'HTMLVideoElement' (TWebHTMLMediaElement)
    videoHeight: NativeInt;
    videoWidth: NativeInt;
  end;

  TJSHTMLVideoElementEx = TWebHTMLVideoElement;

  TWebMediaDevicesInfo = class external name 'MediaDevicesInfo' (TJSObject)
    deviceId: string;
    kind: string;
    &label: string;
  end;

  TJSMediaDevicesInfoEx = TWebMediaDevicesInfo;

  TWebMediaDevices = class external name 'MediaDevices' (TJSEventTarget)
    function enumerateDevices: TJSPromise;
    function getUserMedia(aConstraints: TJSObject): TJSPromise;
    function getSupportedConstraints: TJSObject;
    function getDisplayMedia(aConstraints: TJSObject): TJSPromise;
  end;

  TJSMediaDevicesEx = TWebMediaDevices;

  TWebJSQRResultObject = class external name 'QRCode' (TJSObject)
    binaryData: TJSUint8ClampedArray;
    data: string;
  end;

  TWebQuaggaJSConfigObject = class external name 'Object' (TJSObject)
    decoder: TJSObject;
    src: string; //can be url or base64 data string
  end;

  TWebQuaggaJSResultObject_CodeResult = class external name 'Object' (TJSObject)
    code: string;
  end;

  TWebQuaggaJSResultObject = class external name 'Object' (TJSObject)
    codeResult: TWebQuaggaJSResultObject_CodeResult;
  end;

  TWebQuaggaJSResultCallback = reference to procedure(data: TWebQuaggaJSResultObject);

  TWebQuagga  = class external name 'Quagga' (TJSObject)
    procedure decodeSingle(aConfig: TWebQuaggaJSConfigObject; aResultCallback: TWebQuaggaJSResultCallback);
  end;

function jsQR(aImageData: TJSUint8ClampedArray; aWidth: NativeInt; aHeight: NativeInt): TWebJSQRResultObject; external name 'jsQR';

var
  Quagga: TWebQuagga; external name 'Quagga';

implementation

end.

//-----------------------------------------------------------------------------
// Unit Name: a_main
// Author:    Rademacker
// Date:      2025-02-04
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit a_main;

interface

uses
    System.SysUtils,
    System.Classes,
    JS,
    Web,
    WEBLib.Graphics,
    WEBLib.Controls,
    WEBLib.Forms,
    WEBLib.Dialogs,
    WEBLib.Menus,
    WEBLib.ExtCtrls,
    WEBLib.StdCtrls,
    i_user;

type
  TfrmMain = class(TForm)
    btn_gutschein: TButton;
    edname3: TLabel;
    edgut2: TLabel;
    edgut1: TLabel;
    edname2: TLabel;
    edname1: TLabel;
    logout: TLinkLabel;
    pagegs: TLinkLabel;
    WebLinkLabel1: TLinkLabel;
    WebWaitMessage1: TWaitMessage;
    procedure frmMainCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure btn_gutscheinClick(Sender: TObject);
    procedure logoutClick(Sender: TObject);
    procedure pagegsClick(Sender: TObject);
  private
    { Private-Deklarationen }
    procedure InitDisplay();
    [async] procedure DoLogin(); async;
    [async] procedure GetUserInfo(); async;
    [async] procedure ScanAffiliate(); async;
    [async] procedure ShowError(const cError: string); async;
    [async] procedure ShowVoucher(const cId: string); async;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var frmMain: TfrmMain;

implementation

uses
    lib_Utils,
    u_login,
    u_scanner,
    u_error,
    u_voucher;

{$R *.dfm}

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.frmMainCreate
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.frmMainCreate(Sender: TObject);
begin
    oUser := TxUser.Create();

    InitDisplay();
    DoLogin();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.logoutClick
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.logoutClick(Sender: TObject);
begin
    oUser.Logout();
    InitDisplay();
    DoLogin();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.pagegsClick
// Author:    Rademacker
// Date:      2025-03-18
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.pagegsClick(Sender: TObject);
begin
    //
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.WebFormDestroy
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.WebFormDestroy(Sender: TObject);
begin
    FreeAndNil(oUser);
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.btn_gutscheinClick
// Author:    Rademacker
// Date:      2025-02-05
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.btn_gutscheinClick(Sender: TObject);
begin
    ScanAffiliate();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.InitDisplay
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.InitDisplay();
begin
    logout.Caption  := 'Abmelden';
    edname1.Caption := '';
    edname2.Caption := '';
    edname3.Caption := '';
    edgut1.Caption  := '0 offen';
    edgut2.Caption  := '0 eingelöst';
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.DoLogin
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.DoLogin();
begin
    if (oUser.CheckLogin()) or (await(Boolean, TxWebUtils.ShowModal<TfrmLogin>())) then begin
        GetUserInfo();
    end else begin
        ShowError('Login fehlgeschlagen!');
    end;
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.GetUserInfo
// Author:    Rademacker
// Date:      2025-03-13
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.GetUserInfo();
begin
    WebWaitMessage1.Show();

    if (Await(Boolean, oUser.GetUserInfo())) then begin
        edname1.Caption := oUser.Username;
        edname2.Caption := oUser.Name;
        edname3.Caption := oUser.Adresse;
        edgut1.Caption  := IntToStr(oUser.GsGesamt) + ' verbraucht';
        edgut2.Caption  := IntToStr(oUser.GsOffen) + ' verfügbar';
    end;

    WebWaitMessage1.Hide();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.ScanAffiliate
// Author:    Rademacker
// Date:      2025-02-18
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.ScanAffiliate();
var oFrm: TfrmScanner;
    //nPos: integer;
begin
    oFrm := TfrmScanner.Create(nil);
    oFrm.Popup := false;
    Await(TfrmScanner, oFrm.Load());
    oFrm.StartScan();

    if (Await(TModalResult, oFrm.Execute()) = mrOK) then begin
        //MessageDlg(oFrm.cCode, mtInformation, []);
        if (not oFrm.cCode.IsEmpty) then begin
            if (Await(boolean, oUser.GetNewVoucher(oFrm.cCode))) then begin
                //MessageDlg(oUser.GsNew, mtInformation, []);
                //
            end else begin
                // TODO
            end;
        end;
    end;

    oFrm.Free();

    await(GetUserInfo());

    // neuen Gutschein anzeigen
    if (not oUser.GsNew.IsEmpty) then begin
        ShowVoucher(oUser.GsNew);
    end;
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.ShowError
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.ShowError(const cError: string);
var oFrm: TfrmError;
begin
    oFrm := TfrmError.Create(nil);
    oFrm.Popup := false;
    Await(TfrmError, oFrm.Load());
    oFrm.SetMsg(cError);
    oFrm.Execute();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmMain.ShowVoucher
// Author:    Rademacker
// Date:      2025-03-18
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmMain.ShowVoucher(const cId: string);
var oFrm: TfrmVoucher;
begin
    oFrm := TfrmVoucher.Create(nil);
    oFrm.Popup := false;
    Await(TfrmVoucher, oFrm.Load());
    oFrm.SetVoucherInfo(cId);
    oFrm.Execute();
end;

procedure TfrmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  btn_gutschein := TButton.Create('btn_gutschein');
  edname3 := TLabel.Create('ed_name3');
  edgut2 := TLabel.Create('ed_gut2');
  edgut1 := TLabel.Create('ed_gut1');
  edname2 := TLabel.Create('ed_name2');
  edname1 := TLabel.Create('ed_name1');
  logout := TLinkLabel.Create('logout');
  pagegs := TLinkLabel.Create('pagegs');
  WebWaitMessage1 := TWaitMessage.Create(Self);

  btn_gutschein.BeforeLoadDFMValues;
  edname3.BeforeLoadDFMValues;
  edgut2.BeforeLoadDFMValues;
  edgut1.BeforeLoadDFMValues;
  edname2.BeforeLoadDFMValues;
  edname1.BeforeLoadDFMValues;
  logout.BeforeLoadDFMValues;
  pagegs.BeforeLoadDFMValues;
  WebWaitMessage1.BeforeLoadDFMValues;
  try
    Name := 'frmMain';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    ElementFont := efCSS;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 554;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'frmMainCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    TabOrder := 0;
    Top := 0;
    Width := 402;
    btn_gutschein.SetParentComponent(Self);
    btn_gutschein.Name := 'btn_gutschein';
    btn_gutschein.Caption := 'Gutschein einl'#246'sen';
    btn_gutschein.Color := clNone;
    btn_gutschein.ElementClassName := 'btn btn-light';
    btn_gutschein.ElementFont := efCSS;
    btn_gutschein.ElementPosition := epRelative;
    btn_gutschein.Font.Charset := ANSI_CHARSET;
    btn_gutschein.Font.Color := clBlack;
    btn_gutschein.Font.Height := -11;
    btn_gutschein.Font.Name := 'Arial';
    btn_gutschein.Font.Size := 8;
    btn_gutschein.Font.Style := [];
    btn_gutschein.Height := 30;
    btn_gutschein.HeightStyle := ssAuto;
    btn_gutschein.Left := 24;
    SetEvent(btn_gutschein, Self, 'OnClick', 'btn_gutscheinClick');
    btn_gutschein.Role := 'button';
    btn_gutschein.TabOrder := 0;
    btn_gutschein.Top := 363;
    btn_gutschein.Width := 304;
    edname3.SetParentComponent(Self);
    edname3.Name := 'edname3';
    edname3.ElementPosition := epRelative;
    edname3.Font.Charset := ANSI_CHARSET;
    edname3.Font.Color := clBlack;
    edname3.Font.Height := -11;
    edname3.Font.Name := 'Arial';
    edname3.Font.Size := 8;
    edname3.Font.Style := [];
    edname3.Height := 30;
    edname3.Left := 0;
    edname3.Top := 0;
    edname3.Width := 205;
    edgut2.SetParentComponent(Self);
    edgut2.Name := 'edgut2';
    edgut2.ElementPosition := epRelative;
    edgut2.Font.Charset := ANSI_CHARSET;
    edgut2.Font.Color := clBlack;
    edgut2.Font.Height := -11;
    edgut2.Font.Name := 'Arial';
    edgut2.Font.Size := 8;
    edgut2.Font.Style := [];
    edgut2.Height := 30;
    edgut2.Left := 101;
    edgut2.Top := 0;
    edgut2.Width := 205;
    edgut1.SetParentComponent(Self);
    edgut1.Name := 'edgut1';
    edgut1.ElementPosition := epRelative;
    edgut1.Font.Charset := ANSI_CHARSET;
    edgut1.Font.Color := clBlack;
    edgut1.Font.Height := -11;
    edgut1.Font.Name := 'Arial';
    edgut1.Font.Size := 8;
    edgut1.Font.Style := [];
    edgut1.Height := 30;
    edgut1.Left := 202;
    edgut1.Top := 0;
    edgut1.Width := 205;
    edname2.SetParentComponent(Self);
    edname2.Name := 'edname2';
    edname2.ElementPosition := epRelative;
    edname2.Font.Charset := ANSI_CHARSET;
    edname2.Font.Color := clBlack;
    edname2.Font.Height := -11;
    edname2.Font.Name := 'Arial';
    edname2.Font.Size := 8;
    edname2.Font.Style := [];
    edname2.Height := 30;
    edname2.Left := 303;
    edname2.Top := 0;
    edname2.Width := 205;
    edname1.SetParentComponent(Self);
    edname1.Name := 'edname1';
    edname1.ElementPosition := epRelative;
    edname1.Font.Charset := ANSI_CHARSET;
    edname1.Font.Color := clBlack;
    edname1.Font.Height := -11;
    edname1.Font.Name := 'Arial';
    edname1.Font.Size := 8;
    edname1.Font.Style := [];
    edname1.Height := 30;
    edname1.Left := 404;
    edname1.Top := 0;
    edname1.Width := 205;
    logout.SetParentComponent(Self);
    logout.Name := 'logout';
    logout.ElementPosition := epRelative;
    logout.Font.Charset := ANSI_CHARSET;
    logout.Font.Color := clBlack;
    logout.Font.Height := -11;
    logout.Font.Name := 'Arial';
    logout.Font.Size := 8;
    logout.Font.Style := [];
    logout.Height := 30;
    logout.Left := 0;
    SetEvent(logout, Self, 'OnClick', 'logoutClick');
    logout.Top := 0;
    logout.Width := 101;
    pagegs.SetParentComponent(Self);
    pagegs.Name := 'pagegs';
    pagegs.ElementPosition := epRelative;
    pagegs.Font.Charset := ANSI_CHARSET;
    pagegs.Font.Color := clBlack;
    pagegs.Font.Height := -11;
    pagegs.Font.Name := 'Arial';
    pagegs.Font.Size := 8;
    pagegs.Font.Style := [];
    pagegs.Height := 30;
    pagegs.Left := 0;
    SetEvent(pagegs, Self, 'OnClick', 'pagegsClick');
    pagegs.Top := 0;
    pagegs.Width := 101;
    WebWaitMessage1.SetParentComponent(Self);
    WebWaitMessage1.Name := 'WebWaitMessage1';
    WebWaitMessage1.Height := 25;
    WebWaitMessage1.Left := 0;
    WebWaitMessage1.Opacity := 0.200000000000000000;
    WebWaitMessage1.Picture.LoadFromFile('a_main.WebWaitMessage1.Picture.gif');
    WebWaitMessage1.TimeOut := 0;
    WebWaitMessage1.Top := 0;
    WebWaitMessage1.Width := 100;
    WebWaitMessage1.Left := 30;
    WebWaitMessage1.Top := 119;
  finally
    btn_gutschein.AfterLoadDFMValues;
    edname3.AfterLoadDFMValues;
    edgut2.AfterLoadDFMValues;
    edgut1.AfterLoadDFMValues;
    edname2.AfterLoadDFMValues;
    edname1.AfterLoadDFMValues;
    logout.AfterLoadDFMValues;
    pagegs.AfterLoadDFMValues;
    WebWaitMessage1.AfterLoadDFMValues;
  end;
end;

end.
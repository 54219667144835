//-----------------------------------------------------------------------------
// Unit Name: u_error
// Author:    Rademacker
// Date:      2025-02-20
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit u_error;

interface

uses
    System.SysUtils,
    System.Classes,
    JS,
    Web,
    WEBLib.Graphics,
    WEBLib.Controls,
    WEBLib.Forms,
    WEBLib.Dialogs,
    WEBLib.Menus,
    WEBLib.StdCtrls;

type
  TfrmError = class(TForm)
    labmsg: TLabel;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    procedure SetMsg(const cMsg: string);
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

procedure TfrmError.SetMsg(const cMsg: string);
begin
    labmsg.Caption := cMsg;
end;

procedure TfrmError.LoadDFMValues;
begin
  inherited LoadDFMValues;

  labmsg := TLabel.Create('lab_msg');

  labmsg.BeforeLoadDFMValues;
  try
    Name := 'frmError';
    Caption := 'Fehler';
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    Height := 482;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'frmErrorCreate');
    TabOrder := 0;
    Top := 0;
    Width := 354;
    labmsg.SetParentComponent(Self);
    labmsg.Name := 'labmsg';
    labmsg.ElementPosition := epRelative;
    labmsg.Font.Charset := ANSI_CHARSET;
    labmsg.Font.Color := clBlack;
    labmsg.Font.Height := -11;
    labmsg.Font.Name := 'Arial';
    labmsg.Font.Size := 8;
    labmsg.Font.Style := [];
    labmsg.Height := 34;
    labmsg.Left := 0;
    labmsg.Top := 0;
    labmsg.Width := 34;
  finally
    labmsg.AfterLoadDFMValues;
  end;
end;

end.
//-----------------------------------------------------------------------------
// Unit Name: u_scanner
// Author:    Rademacker
// Date:      2025-02-18
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit u_scanner;

interface

uses
    System.SysUtils,
    System.Classes,
    JS,
    Web,
    WEBLib.Graphics,
    WEBLib.Controls,
    WEBLib.Forms,
    WEBLib.Dialogs,
    uc_page,
    WEBLib.Menus,
    WEBLib.StdCtrls,
    WEBLib.Devices,
    WEBLib.ExtCtrls;

type
  TfrmScanner = class(TfrmPage)
    WebLabel1: TLabel;
    WebCamera1: TCamera;
    WebQRDecoder1: TQRDecoder;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebInputMessageDlg1: TInputMessageDlg;
    procedure WebQRDecoder1Decoded(Sender: TObject; ADecoded: string);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    [async] procedure WebButton2Click(Sender: TObject); async;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    cCode: string;
    procedure StartScan();
  protected procedure LoadDFMValues; override; end;

implementation

uses
    i_user;

{$R *.dfm}

// -----------------------------------------------------------------------------
// Procedure: TfrmScanner.WebFormClose
// Author:    Rademacker
// Date:      2025-03-17
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmScanner.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
    WebCamera1.Stop();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmScanner.StartScan
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmScanner.StartScan();
begin
    Self.cCode := '';
    WebCamera1.Start();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmScanner.WebQRDecoder1Decoded
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmScanner.WebQRDecoder1Decoded(Sender: TObject; ADecoded: string);
begin
    Self.cCode := ADecoded;
    WebCamera1.Stop();
    ModalResult := mrOK;
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmScanner.WebButton2Click
// Author:    Rademacker
// Date:      2025-03-17
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmScanner.WebButton2Click(Sender: TObject);
var res: TModalResult;
begin
    WebInputMessageDlg1.DialogType := mtCustom;
    WebInputMessageDlg1.InputValue := '';
    WebInputMessageDlg1.InputType  := itText;
    WebInputMessageDlg1.Message    := 'Code der Ausgabestelle';
    WebInputMessageDlg1.Buttons    := [mbOk, mbAbort];

    res := await(TModalResult, WebInputMessageDlg1.ShowAsync);

    if (res = mrOK) then begin
        Self.cCode := WebInputMessageDlg1.InputValue;
        WebCamera1.Stop();
        ModalResult := mrOK;
    end;
end;

procedure TfrmScanner.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebCamera1 := TCamera.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebQRDecoder1 := TQRDecoder.Create(Self);
  WebInputMessageDlg1 := TInputMessageDlg.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebCamera1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebQRDecoder1.BeforeLoadDFMValues;
  WebInputMessageDlg1.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnClose', 'WebFormClose');
    WebPanel1.Height := 38;
    WebButton1.Height := 30;
    WebButton1.Left := 5;
    SetEvent(WebButton1, Self, 'OnClick', 'btnBackClick');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Anchors := [akLeft,akTop,akRight];
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'QR-Code der Ausgabestelle scannen';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -11;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 8;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 24;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.Left := 10;
    WebLabel1.Top := 47;
    WebLabel1.Width := 280;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Anchors := [akLeft,akTop,akRight,akBottom];
    WebPanel2.Caption := 'WebPanel2';
    WebPanel2.Color := clBtnFace;
    WebPanel2.Font.Charset := ANSI_CHARSET;
    WebPanel2.Font.Color := clBlack;
    WebPanel2.Font.Height := -11;
    WebPanel2.Font.Name := 'Arial';
    WebPanel2.Font.Size := 8;
    WebPanel2.Font.Style := [];
    WebPanel2.Height := 368;
    WebPanel2.Left := 7;
    WebPanel2.Role := 'null';
    WebPanel2.TabOrder := 0;
    WebPanel2.TabStop := False;
    WebPanel2.Top := 83;
    WebPanel2.Width := 344;
    WebCamera1.SetParentComponent(WebPanel2);
    WebCamera1.Name := 'WebCamera1';
    WebCamera1.Align := alClient;
    WebCamera1.Anchors := [akLeft,akTop,akRight,akBottom];
    WebCamera1.BaseFormat := cbfPNG;
    WebCamera1.CameraType := ctRear;
    WebCamera1.Height := 366;
    WebCamera1.Left := 0;
    WebCamera1.Top := 0;
    WebCamera1.Width := 342;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Anchors := [akLeft,akBottom];
    WebButton2.Caption := 'manuelle Eingabe';
    WebButton2.Color := clNone;
    WebButton2.ElementClassName := 'btn btn-light';
    WebButton2.ElementFont := efCSS;
    WebButton2.Font.Charset := ANSI_CHARSET;
    WebButton2.Font.Color := clBlack;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Size := 8;
    WebButton2.Font.Style := [];
    WebButton2.Height := 38;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.Left := 10;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton2.Role := 'button';
    WebButton2.TabOrder := 0;
    WebButton2.Top := 460;
    WebButton2.Width := 216;
    WebQRDecoder1.SetParentComponent(Self);
    WebQRDecoder1.Name := 'WebQRDecoder1';
    WebQRDecoder1.Camera := WebCamera1;
    WebQRDecoder1.EnableTimer := True;
    SetEvent(WebQRDecoder1, Self, 'OnDecoded', 'WebQRDecoder1Decoded');
    WebQRDecoder1.Left := 38;
    WebQRDecoder1.Top := 304;
    WebInputMessageDlg1.SetParentComponent(Self);
    WebInputMessageDlg1.Name := 'WebInputMessageDlg1';
    WebInputMessageDlg1.DialogText.BeginUpdate;
    try
      WebInputMessageDlg1.DialogText.Clear;
      WebInputMessageDlg1.DialogText.Add('Warning');
      WebInputMessageDlg1.DialogText.Add('Error');
      WebInputMessageDlg1.DialogText.Add('Information');
      WebInputMessageDlg1.DialogText.Add('Confirm');
      WebInputMessageDlg1.DialogText.Add('Custom');
      WebInputMessageDlg1.DialogText.Add('OK');
      WebInputMessageDlg1.DialogText.Add('Cancel');
      WebInputMessageDlg1.DialogText.Add('Yes');
      WebInputMessageDlg1.DialogText.Add('No');
      WebInputMessageDlg1.DialogText.Add('Abort');
      WebInputMessageDlg1.DialogText.Add('Retry');
      WebInputMessageDlg1.DialogText.Add('Ignore');
      WebInputMessageDlg1.DialogText.Add('All');
      WebInputMessageDlg1.DialogText.Add('Yes to all');
      WebInputMessageDlg1.DialogText.Add('No to all');
      WebInputMessageDlg1.DialogText.Add('Help');
      WebInputMessageDlg1.DialogText.Add('Close');
    finally
      WebInputMessageDlg1.DialogText.EndUpdate;
    end;
    WebInputMessageDlg1.Height := 25;
    WebInputMessageDlg1.Left := 153;
    WebInputMessageDlg1.Opacity := 0.200000000000000000;
    WebInputMessageDlg1.Top := 308;
    WebInputMessageDlg1.Width := 100;
    WebInputMessageDlg1.Left := 153;
    WebInputMessageDlg1.Top := 308;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebCamera1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebQRDecoder1.AfterLoadDFMValues;
    WebInputMessageDlg1.AfterLoadDFMValues;
  end;
end;

end.

//-----------------------------------------------------------------------------
// Unit Name: uc_page
// Author:    Rademacker
// Date:      2025-02-18
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit uc_page;

interface

uses
    System.SysUtils,
    System.Classes,
    JS,
    Web,
    WEBLib.Graphics,
    WEBLib.Controls,
    WEBLib.Forms,
    WEBLib.Dialogs,
    WEBLib.Menus,
    WEBLib.StdCtrls,
    WEBLib.ExtCtrls;

type
  TfrmPage = class(TForm)
    WebPanel1: TPanel;
    WebButton1: TButton;
    procedure btnBackClick(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

implementation

uses
    System.UITypes;

{$R *.dfm}

// -----------------------------------------------------------------------------
// Procedure: TfrmPage.btnBackClick
// Author:    Rademacker
// Date:      2025-02-20
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmPage.btnBackClick(Sender: TObject);
begin
    ModalResult := mrCancel;
end;

procedure TfrmPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'frmPage';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    ElementFont := efCSS;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 498;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 354;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Align := alTop;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.Color := clBtnFace;
    WebPanel1.Font.Charset := ANSI_CHARSET;
    WebPanel1.Font.Color := clBlack;
    WebPanel1.Font.Height := -11;
    WebPanel1.Font.Name := 'Arial';
    WebPanel1.Font.Size := 8;
    WebPanel1.Font.Style := [];
    WebPanel1.Height := 44;
    WebPanel1.Left := 0;
    WebPanel1.TabOrder := 0;
    WebPanel1.TabStop := False;
    WebPanel1.Top := 0;
    WebPanel1.Width := 354;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Color := clNone;
    WebButton1.ElementClassName := 'btn btn-light bi bi-arrow-left';
    WebButton1.ElementFont := efCSS;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 30;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.Left := 6;
    SetEvent(WebButton1, Self, 'OnClick', 'btnBackClick');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 4;
    WebButton1.Width := 40;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.
//-----------------------------------------------------------------------------
// Unit Name: lib_REST
// Author:    Rademacker
// Date:      2025-01-31
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit lib_REST;

interface

uses
    System.Classes,
    Web,
    JS,
    WEBLib.Controls,
    WEBLib.REST;

type
    TxRestRequest = class(TObject)
    private
        oRequest: TWebHttpRequest;
        //oRequest : TWebRESTClient;
        cApiKey  : string;
        cHost    : string;
        nPort    : integer;
        oResponse: TJSObject;
        lSsl     : Boolean;
        cJwt     : string;
        //cResponse: string;
        //pResponse: TNotifyEvent;
        //procedure _OnResponse(Sender: TObject; AResponse: string);
        procedure _OnError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord; var Handled: boolean);
        function CreateUrl(const cEndpoint: string): string;
        function CreateHeaders(): TCoreCloudHeaders;
        function GetResponse(): TJSObject;
    public
        constructor Create();
        destructor Destroy(); override;
        //[async]
        function Get(const cEndpoint: string; oParams: TStringList = nil): TJSPromise;
        function Post(const cEndpoint: string; oParams: TStringList = nil): TJSPromise;
    public
        property ApiKey  : string    read cApiKey write cApiKey;
        property Host    : string    read cHost   write cHost;
        property Port    : integer   read nPort   write nPort;
        property Response: TJSObject read GetResponse;
        property Ssl     : Boolean   read lSsl    write lSsl;
        property Jwt     : string    read cJwt    write cJwt;
        // Events
        //property OnResponse: TNotifyEvent read pResponse write pResponse;
    end;

implementation

uses
    System.SysUtils;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest.Create
// Author:    Rademacker
// Date:      2025-01-31
// Comment:
// -----------------------------------------------------------------------------

constructor TxRestRequest.Create();
begin
    inherited Create();

    oRequest  := TWebHttpRequest.Create(nil);
    //oRequest  := TWebRESTClient.Create(nil);
    oRequest.Timeout      := 10000;
    oRequest.ResponseType := rtJSON;
    //oRequest.OnResponse   := _OnResponse;
    oRequest.OnError      := _OnError;

    Self.cApiKey   := '';
    Self.cHost     := '';
    Self.nPort     := 8000;
    Self.oResponse := nil;
    Self.lSsl      := true;
    Self.cJwt      := '';
    //Self.cResponse := '';
    //Self.Response := nil;
end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest.Destroy
// Author:    Rademacker
// Date:      2025-01-31
// Comment:
// -----------------------------------------------------------------------------

destructor TxRestRequest.Destroy();
begin
    FreeAndNil(oRequest);

    inherited Destroy();
end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest.Get
// Author:    Rademacker
// Date:      2025-01-31
// Comment:
// -----------------------------------------------------------------------------

function TxRestRequest.Get(const cEndpoint: string; oParams: TStringList = nil): TJSPromise;
//var oResp: TJSXMLHttpRequest;
begin
    //Self.cResponse := '';
    // TODO https auf dem REST-Server
    //oRequest.HttpsGet(CreateUrl(cEndpoint), CreateHeaders());

    //oResp := await(TJSXMLHttpRequest, oRequest.HttpRequest('GET', CreateUrl(cEndpoint), '', '', CreateHeaders());
    //oRequest.HttpRequest('GET', CreateUrl(cEndpoint), '', '', CreateHeaders());

    oRequest.Command := httpGET;
    oRequest.URL := CreateUrl(cEndpoint);

    //oRequest.Headers.Add('Access-Control-Allow-Origin=*');

    oRequest.Headers.Clear();

    if (Self.cJwt <> '') then begin
        oRequest.Headers.Add('Authorization=Bearer ' + Self.cJwt);
    end;

    if (Self.cApiKey <> '') then begin
        oRequest.Headers.Add('apikey=' + Self.cApiKey);
    end;

    if (Assigned(oParams)) then begin
        oRequest.Headers.AddStrings(oParams);
    end;

    result := TJSPromise.new(
        procedure(ASuccess, AFailed: TJSPromiseResolver)
        begin
            oRequest.Execute(
                procedure(AResponse: string; ARequest: TJSXMLHttpRequest)
                begin
                    //ASuccess(ARequest);
                    if (ARequest.Status = 200) then begin
                        Self.oResponse := TJSObject(ARequest.response);
                        ASuccess(true);
                    end else begin
                        ASuccess(false);
                    end;
                end,
                procedure(ARequest: TJSXMLHttpRequest)
                begin
                    //AFailed(ARequest);
                    ASuccess(false);
                end,
                procedure(ARequest: TJSXMLHttpRequest)
                begin
                    //AFailed(ARequest);
                    ASuccess(false);
                end);

            //oRequest.Execute();
            //oResp := await(TJSXMLHttpRequest, oRequest.Perform());

//            if (oResp.Status = 200) then begin
//                Self.cResponse := JS.toString(TJSObject(oResp.response)['value']);
//                //_OnResponse(Self, JS.toString(TJSObject(oResp.response)['value']));
//                ASuccess(true);
//            end;
        end
    );
    //oRequest.Execute();
    //oResp := await(TJSXMLHttpRequest, oRequest.Perform);
end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest.Post
// Author:    Rademacker
// Date:      2025-03-17
// Comment:
// -----------------------------------------------------------------------------

function TxRestRequest.Post(const cEndpoint: string; oParams: TStringList = nil): TJSPromise;
begin
    oRequest.Command := httpPOST;
    oRequest.URL := CreateUrl(cEndpoint);

    oRequest.Headers.Clear();

    if (Self.cJwt <> '') then begin
        oRequest.Headers.Add('Authorization=Bearer ' + Self.cJwt);
    end;

    if (Self.cApiKey <> '') then begin
        oRequest.Headers.Add('apikey=' + Self.cApiKey);
    end;

    if (Assigned(oParams)) then begin
        oRequest.Headers.AddStrings(oParams);
    end;

    result := TJSPromise.new(
        procedure(ASuccess, AFailed: TJSPromiseResolver)
        begin
            oRequest.Execute(
                procedure(AResponse: string; ARequest: TJSXMLHttpRequest)
                begin
                    if (ARequest.Status = 200) then begin
                        Self.oResponse := TJSObject(ARequest.response);
                        ASuccess(true);
                    end else begin
                        ASuccess(false);
                    end;
                end,
                procedure(ARequest: TJSXMLHttpRequest)
                begin
                    ASuccess(false);
                end,
                procedure(ARequest: TJSXMLHttpRequest)
                begin
                    ASuccess(false);
                end);
        end
    );
end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest._OnResponse
// Author:    Rademacker
// Date:      2025-01-31
// Comment:
// -----------------------------------------------------------------------------

//procedure TxRestRequest._OnResponse(Sender: TObject; AResponse: string);
//begin
//    Self.cResponse := AResponse;
//
//    if (Assigned(pResponse)) then begin
//        pResponse(Self);
//    end;
//end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest._OnError
// Author:    Rademacker
// Date:      2025-01-31
// Comment:
// -----------------------------------------------------------------------------

procedure TxRestRequest._OnError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord; var Handled: boolean);
begin
    Handled := true;
    // TODO nur in Debug
    // und req kann nil sein?
    //console.info('Error: ' + ARequest.req.responseText);
end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest.CreateUrl
// Author:    Rademacker
// Date:      2025-01-31
// Comment:
// -----------------------------------------------------------------------------

function TxRestRequest.CreateUrl(const cEndpoint: string): string;
var cProto: string;
begin
    if (Self.lSsl) then begin
        cProto := 'https';
    end else begin
        cProto := 'http';
    end;

    result := Format('%s://%s:%d/%s', [cProto, Self.cHost, Self.nPort, cEndpoint]);
end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest.CreateHeaders
// Author:    Rademacker
// Date:      2025-01-31
// Comment:
// -----------------------------------------------------------------------------

function TxRestRequest.CreateHeaders(): TCoreCloudHeaders;
begin
    AddHeader(result, 'Access-Control-Allow-Origin', '*');
    AddHeader(result, 'apikey', Self.cApiKey);
end;

// -----------------------------------------------------------------------------
// Procedure: TxRestRequest.GetResponse
// Author:    Rademacker
// Date:      2025-02-25
// Comment:
// -----------------------------------------------------------------------------

function TxRestRequest.GetResponse(): TJSObject;
begin
    result := oResponse;
end;

end.

//-----------------------------------------------------------------------------
// Unit Name: u_login
// Author:    Rademacker
// Date:      2025-02-04
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit u_login;

interface

uses
    System.SysUtils,
    System.Classes,
    JS,
    Web,
    WEBLib.Graphics,
    WEBLib.Controls,
    WEBLib.Forms,
    WEBLib.Dialogs,
    WEBLib.Menus,
    WEBLib.StdCtrls;

type
    TfrmLogin = class(TForm)
        ed_user: TEdit;
        ed_pass: TEdit;
        btn_login: TButton;
        cb_remember: TCheckBox;
        WebWaitMessage1: TWaitMessage;
        procedure frmLoginCreate(Sender: TObject);
        procedure btn_loginClick(Sender: TObject);
    private
        { Private-Deklarationen }
        nRetry: integer;
        [async] procedure TryLogin(); async;
    public
        { Public-Deklarationen }
    protected procedure LoadDFMValues; override; end;

var frmLogin: TfrmLogin;

implementation

uses
    System.UITypes,
    lib_Utils,
    i_user;

{$R *.dfm}

// -----------------------------------------------------------------------------
// Procedure: TfrmLogin.frmLoginCreate
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmLogin.frmLoginCreate(Sender: TObject);
begin
    nRetry := 0;
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmLogin.btn_loginClick
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmLogin.btn_loginClick(Sender: TObject);
begin
    TryLogin();
end;

// -----------------------------------------------------------------------------
// Procedure: TfrmLogin.TryLogin
// Author:    Rademacker
// Date:      2025-02-04
// Comment:
// -----------------------------------------------------------------------------

procedure TfrmLogin.TryLogin();
begin
    WebWaitMessage1.Show();

    if (Await(Boolean, oUser.DoAuth(ed_user.Text, ed_pass.Text, cb_remember.Checked))) then begin
        Self.ModalResult := mrOK;
    end else begin
        Inc(nRetry);
        if (nRetry >= 3) then begin
            Self.ModalResult := mrCancel;
        end else begin
            TxSBUtils.InvalidateFormControl(ed_user.ElementHandle, false);
            TxSBUtils.InvalidateFormControl(ed_pass.ElementHandle, false);
            // TODO
            //lab_error.Caption := 'Login fehlgeschlagen';
            //lab_error.Visible := true;
        end;
    end;

    WebWaitMessage1.Hide();
end;

procedure TfrmLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ed_user := TEdit.Create('ed_user');
  ed_pass := TEdit.Create('ed_pass');
  btn_login := TButton.Create('btn_login');
  cb_remember := TCheckBox.Create('cb_remember');
  WebWaitMessage1 := TWaitMessage.Create(Self);

  ed_user.BeforeLoadDFMValues;
  ed_pass.BeforeLoadDFMValues;
  btn_login.BeforeLoadDFMValues;
  cb_remember.BeforeLoadDFMValues;
  WebWaitMessage1.BeforeLoadDFMValues;
  try
    Name := 'frmLogin';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    ElementFont := efCSS;
    ElementPosition := epRelative;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 554;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'frmLoginCreate');
    TabOrder := 0;
    Top := 0;
    Width := 426;
    ed_user.SetParentComponent(Self);
    ed_user.Name := 'ed_user';
    ed_user.Alignment := taLeftJustify;
    ed_user.Color := clWindow;
    ed_user.ElementClassName := 'form-control';
    ed_user.ElementFont := efCSS;
    ed_user.ElementPosition := epRelative;
    ed_user.Font.Charset := ANSI_CHARSET;
    ed_user.Font.Color := clBlack;
    ed_user.Font.Height := -11;
    ed_user.Font.Name := 'Arial';
    ed_user.Font.Size := 8;
    ed_user.Font.Style := [];
    ed_user.Height := 38;
    ed_user.HeightStyle := ssAuto;
    ed_user.HideSelection := False;
    ed_user.Left := 17;
    SetEvent(ed_user, Self, 'OnClick', 'ed_userClick');
    ed_user.ShowFocus := True;
    ed_user.TabOrder := 0;
    ed_user.Top := 85;
    ed_user.Width := 378;
    ed_user.WidthStyle := ssAuto;
    ed_pass.SetParentComponent(Self);
    ed_pass.Name := 'ed_pass';
    ed_pass.Alignment := taLeftJustify;
    ed_pass.Color := clWindow;
    ed_pass.ElementClassName := 'form-control';
    ed_pass.ElementFont := efCSS;
    ed_pass.ElementPosition := epIgnore;
    ed_pass.Font.Charset := ANSI_CHARSET;
    ed_pass.Font.Color := clBlack;
    ed_pass.Font.Height := -11;
    ed_pass.Font.Name := 'Arial';
    ed_pass.Font.Size := 8;
    ed_pass.Font.Style := [];
    ed_pass.Height := 38;
    ed_pass.HeightStyle := ssAuto;
    ed_pass.HideSelection := False;
    ed_pass.Left := 0;
    ed_pass.ShowFocus := True;
    ed_pass.TabOrder := 0;
    ed_pass.Top := 135;
    ed_pass.Width := 378;
    ed_pass.WidthStyle := ssAuto;
    btn_login.SetParentComponent(Self);
    btn_login.Name := 'btn_login';
    btn_login.Caption := 'Anmelden';
    btn_login.Color := clNone;
    btn_login.ElementClassName := 'btn btn-light';
    btn_login.ElementFont := efCSS;
    btn_login.ElementPosition := epIgnore;
    btn_login.Font.Charset := ANSI_CHARSET;
    btn_login.Font.Color := clBlack;
    btn_login.Font.Height := -11;
    btn_login.Font.Name := 'Arial';
    btn_login.Font.Size := 8;
    btn_login.Font.Style := [];
    btn_login.Height := 38;
    btn_login.HeightStyle := ssAuto;
    btn_login.Left := 131;
    SetEvent(btn_login, Self, 'OnClick', 'btn_loginClick');
    btn_login.Role := 'button';
    btn_login.TabOrder := 0;
    btn_login.Top := 262;
    btn_login.Width := 98;
    btn_login.WidthStyle := ssAuto;
    cb_remember.SetParentComponent(Self);
    cb_remember.Name := 'cb_remember';
    cb_remember.Caption := 'cb_remember';
    cb_remember.Checked := True;
    cb_remember.ElementButtonClassName := 'custom-control-input';
    cb_remember.ElementClassName := 'custom-control custom-checkbox';
    cb_remember.ElementFont := efCSS;
    cb_remember.ElementLabelClassName := 'custom-control-label';
    cb_remember.ElementPosition := epIgnore;
    cb_remember.Font.Charset := ANSI_CHARSET;
    cb_remember.Font.Color := clBlack;
    cb_remember.Font.Height := -11;
    cb_remember.Font.Name := 'Arial';
    cb_remember.Font.Size := 8;
    cb_remember.Font.Style := [];
    cb_remember.Height := 16;
    cb_remember.HeightStyle := ssAuto;
    cb_remember.Left := 126;
    cb_remember.Role := 'null';
    cb_remember.ShowFocus := True;
    cb_remember.State := cbChecked;
    cb_remember.TabOrder := 0;
    cb_remember.Top := 192;
    cb_remember.Width := 16;
    cb_remember.WidthStyle := ssAuto;
    WebWaitMessage1.SetParentComponent(Self);
    WebWaitMessage1.Name := 'WebWaitMessage1';
    WebWaitMessage1.Height := 25;
    WebWaitMessage1.Left := 247;
    WebWaitMessage1.Opacity := 0.200000000000000000;
    WebWaitMessage1.Picture.LoadFromFile('u_login.WebWaitMessage1.Picture.gif');
    WebWaitMessage1.TimeOut := 0;
    WebWaitMessage1.Top := 159;
    WebWaitMessage1.Width := 100;
    WebWaitMessage1.Left := 247;
    WebWaitMessage1.Top := 159;
  finally
    ed_user.AfterLoadDFMValues;
    ed_pass.AfterLoadDFMValues;
    btn_login.AfterLoadDFMValues;
    cb_remember.AfterLoadDFMValues;
    WebWaitMessage1.AfterLoadDFMValues;
  end;
end;

end.
//-----------------------------------------------------------------------------
// Unit Name: lib_Utils
// Author:    Rademacker
// Date:      2025-02-05
// Purpose:
// History:
//-----------------------------------------------------------------------------

unit lib_Utils;

interface

uses
    WEBLib.Forms,
    Web,
    JS;

type
    TxSBUtils = class(TObject)
    public
        class procedure InvalidateFormControl(const cElementId: string; lValid: Boolean); overload;
        class procedure InvalidateFormControl(const oElement: TJSHTMLElement; lValid: Boolean); overload;
    end;

    TxWebUtils = class(TObject)
    private
    public
        [async]
        class function ShowModal<T: TForm>(): Boolean; async;
    end;

implementation

const
    BS_Inalid = 'is-invalid';

// -----------------------------------------------------------------------------
// Procedure: TxSBUtils.InvalidateFormControl
// Author:    Rademacker
// Date:      2025-02-05
// Comment:
// -----------------------------------------------------------------------------

class procedure TxSBUtils.InvalidateFormControl(const cElementId: string; lValid: Boolean);
var oElement: TJSHTMLElement;
begin
    oElement := document.getHTMLElementById(cElementId);
    InvalidateFormControl(oElement, lValid);
end;

// -----------------------------------------------------------------------------
// Procedure: TxSBUtils.InvalidateFormControl
// Author:    Rademacker
// Date:      2025-02-05
// Comment:
// -----------------------------------------------------------------------------

class procedure TxSBUtils.InvalidateFormControl(const oElement: TJSHTMLElement; lValid: Boolean);
begin
    if (lValid) then begin
        oElement.classList.remove(BS_Inalid);
    end else begin
        oElement.classList.add(BS_Inalid);
    end;
end;

// -----------------------------------------------------------------------------
// Procedure: TxWebUtils.ShowModal
// Author:    Rademacker
// Date:      2025-02-05
// Comment:
// -----------------------------------------------------------------------------

class function TxWebUtils.ShowModal<T>(): Boolean;
var frm: T;
begin
    frm := T.Create(nil);
    frm.Popup := false;
    Await(T, frm.Load());

    try
        result := Await(TModalResult, frm.Execute) = mrOK;
    finally
        frm.Free;
    end;
end;

end.
